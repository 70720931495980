define("shared/components/cluster-driver/driver-googlegke/component", ["exports", "jquery", "shared/mixins/cluster-driver", "shared/utils/util", "shared/components/cluster-driver/driver-googlegke/template"], function (_exports, _jquery, _clusterDriver, _util, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clusterDriver.default, {
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    versionChoiceService: Ember.inject.service('version-choices'),
    google: Ember.inject.service(),
    layout: _template.default,
    configField: 'googleKubernetesEngineConfig',
    step: 1,
    clusterAdvanced: false,
    diskTypeContent: null,
    eipIdContent: null,
    hideNewField: false,
    imageTypeContent: null,
    initialMasterVersion: null,
    locationType: null,
    machineTypes: null,
    maintenanceWindowTimes: null,
    nodeAdvanced: false,
    scopeConfig: null,
    versions: null,
    zones: null,
    isNew: Ember.computed.equal('mode', 'new'),
    editing: Ember.computed.equal('mode', 'edit'),

    init() {
      this._super(...arguments); // defaults


      Ember.setProperties(this, {
        maintenanceWindowTimes: this.google.maintenanceWindows,
        imageTypeContent: this.google.imageTypes,
        diskTypeContent: this.google.diskTypes,
        locationType: this.google.defaultZoneType,
        eipIdContent: [],
        scopeConfig: {}
      });
      let config = Ember.get(this, 'cluster.googleKubernetesEngineConfig');

      if (!config) {
        config = Ember.get(this, 'globalStore').createRecord({
          type: 'googleKubernetesEngineConfig',
          diskSizeGb: 100,
          enableAlphaFeature: false,
          nodeCount: 3,
          machineType: 'n1-standard-2',
          zone: 'us-central1-f',
          clusterIpv4Cidr: '',
          minNodeCount: 1,
          maxNodeCount: 5,
          imageType: 'UBUNTU',
          diskType: 'pd-standard',
          region: 'us-west2',
          taints: [],
          useIpAliases: true,
          ipPolicyCreateSubnetwork: true
        });
        Ember.setProperties(this, {
          'cluster.googleKubernetesEngineConfig': config,
          oauthScopesSelection: this.google.oauthScopeOptions.DEFAULT,
          scopeConfig: {
            userInfo: 'none',
            computeEngine: 'none',
            storage: 'devstorage.read_only',
            taskQueue: 'none',
            bigQuery: 'none',
            cloudSQL: 'none',
            cloudDatastore: 'none',
            stackdriverLoggingAPI: 'logging.write',
            stackdriverMonitoringAPI: 'monitoring',
            cloudPlatform: 'none',
            bigtableData: 'none',
            bigtableAdmin: 'none',
            cloudPub: 'none',
            serviceControl: 'none',
            serviceManagement: 'service.management.readonly',
            stackdriverTrace: 'trace.append',
            cloudSourceRepositories: 'none',
            cloudDebugger: 'none'
          },
          resourceLabels: [],
          labels: [],
          taints: []
        });
      } else {
        const {
          resourceLabels = [],
          labels = [],
          taints = [],
          imageType
        } = config;

        if (!imageType) {
          Ember.set(this, 'hideNewField', true);
        }

        let map = {};

        if (resourceLabels) {
          resourceLabels.map((t = '') => {
            const split = t.split('=');
            Ember.set(map, split[0], split[1]);
          });
          Ember.set(this, 'resourceLabels', map);
        }

        if (labels) {
          labels.map((t = '') => {
            const split = t.split('=');
            Ember.set(map, split[0], split[1]);
          });
          Ember.set(this, 'labels', map);
        }

        if (taints) {
          let _taints = taints.map((t = '') => {
            const splitEffect = t.split(':');
            const splitLabel = (splitEffect[1] || '').split('=');
            return {
              effect: splitEffect[0],
              key: splitLabel[0],
              value: splitLabel[1]
            };
          });

          Ember.set(this, 'taints', _taints);
        } else {
          Ember.set(this, 'taints', []);
        }

        if (!Ember.get(this, 'oauthScopesSelection')) {
          const oauthScopes = Ember.get(config, 'oauthScopes');
          const {
            oauthScopesSelection,
            scopeConfig
          } = this.google.unmapOauthScopes(oauthScopes);
          Ember.set(this, 'oauthScopesSelection', oauthScopesSelection);

          if (scopeConfig) {
            Ember.set(this, 'scopeConfig', scopeConfig);
          }
        }
      }

      Ember.setProperties(this, {
        initialMasterVersion: Ember.get(this, 'config.masterVersion'),
        regionChoices: this.google.regions.map(region => {
          return {
            name: region
          };
        }),
        locationType: Ember.get(this, 'config.zone') ? this.google.defaultZoneType : this.google.defaultRegionType
      });
    },

    actions: {
      clickNext() {
        if (Ember.isEmpty(Ember.get(this, 'config.projectId'))) {
          Ember.set(this, 'config.projectId', this.google.parseProjectId(Ember.get(this, 'config')));
        }

        (0, _jquery.default)('BUTTON[type="submit"]').click();
      },

      checkServiceAccount(cb) {
        Ember.set(this, 'errors', []);
        return Ember.RSVP.all([this.fetchZones(), this.fetchVersions(), this.fetchMachineTypes(), this.fetchNetworks(), this.fetchSubnetworks(), this.fetchServiceAccounts()]).then(() => {
          Ember.set(this, 'step', 2);
          cb(true);
        }).catch(() => {
          cb(false);
        });
      },

      setLabels(section) {
        const out = [];

        for (let key in section) {
          out.pushObject(`${key}=${section[key]}`);
        }

        Ember.set(this, 'config.resourceLabels', out);
      },

      setNodeLabels(section) {
        const out = [];

        for (let key in section) {
          out.pushObject(`${key}=${section[key]}`);
        }

        Ember.set(this, 'config.labels', out);
      },

      updateNameservers(nameservers) {
        Ember.set(this, 'config.masterAuthorizedNetworkCidrBlocks', nameservers);
      },

      setTaints(value) {
        Ember.set(this, 'config.taints', value);
      }

    },
    credentialChanged: Ember.observer('config.credential', function () {
      if (this.saving) {
        return;
      }

      Ember.set(this, 'config.projectId', this.google.parseProjectId(Ember.get(this, 'config')));
    }),
    zoneChanged: Ember.observer('config.zone', 'zones.[]', function () {
      if (this.saving) {
        return;
      }

      const zones = Ember.get(this, 'zones') || [];
      const currentZone = zones.findBy('name', Ember.get(this, 'config.zone'));

      if (!currentZone || currentZone.status.toLowerCase() !== 'up') {
        const newZone = zones.filter(x => x.name.startsWith('us-')).find(x => x.status.toLowerCase() === 'up');

        if (newZone) {
          Ember.set(this, 'config.zone', newZone.name);
        }
      }

      this.fetchVersions();
      this.fetchMachineTypes();
      this.fetchNetworks();
      this.fetchSubnetworks();
      this.fetchServiceAccounts();
    }),
    machineTypeChanged: Ember.observer('config.machineTypes', 'machineTypes.[]', function () {
      if (this.saving) {
        return;
      }

      const types = Ember.get(this, 'machineTypes') || [];
      const current = types.findBy('name', Ember.get(this, 'config.machineType'));

      if (!current) {
        Ember.set(this, 'config.machineType', Ember.get(types, 'firstObject.name'));
      }
    }),
    versionChanged: Ember.observer('config.masterVersion', 'versionChoices.[]', function () {
      const current = Ember.get(this, 'config.masterVersion');

      if (this.saving && current) {
        return;
      }

      const versions = Ember.get(this, 'versionChoices') || [];
      const exists = versions.findBy('value', current);

      if (!exists) {
        Ember.set(this, 'config.masterVersion', Ember.get(versions, 'firstObject.value'));
      }
    }),
    networkChange: Ember.observer('config.network', 'subNetworkContent.[]', function () {
      if (this.saving) {
        return;
      }

      const subNetworkContent = Ember.get(this, 'subNetworkContent') || [];

      if (subNetworkContent.length > 1) {
        const firstNonNullSubnetMatch = subNetworkContent.find(sn => !Ember.isEmpty(sn.value));
        Ember.setProperties(this, {
          'config.subNetwork': firstNonNullSubnetMatch.value,
          'config.ipPolicyCreateSubnetwork': false
        });
        const secondaryIpRangeContent = Ember.get(this, 'secondaryIpRangeContent') || [];

        if (secondaryIpRangeContent.length > 0) {
          const value = secondaryIpRangeContent[0] && secondaryIpRangeContent[0].value;
          Ember.setProperties(this, {
            'config.ipPolicyClusterSecondaryRangeName': value,
            'config.ipPolicyServicesSecondaryRangeName': value
          });
        }
      }
    }),
    subnetworkChange: Ember.observer('config.subNetwork', function () {
      if (this.saving) {
        return;
      }

      const {
        config: {
          subNetwork
        }
      } = this;

      if (Ember.isEmpty(subNetwork)) {
        Ember.set(this, 'config.ipPolicyCreateSubnetwork', true);
      } else {
        Ember.set(this, 'config.ipPolicyCreateSubnetwork', false);
      }
    }),
    secondaryIpRangeContentChange: Ember.observer('secondaryIpRangeContent.[]', 'config.useIpAliases', function () {
      if (this.saving) {
        return;
      }

      const secondaryIpRangeContent = Ember.get(this, 'secondaryIpRangeContent') || [];

      if (secondaryIpRangeContent.length === 0) {
        Ember.set(this, 'config.ipPolicyCreateSubnetwork', true);
      }
    }),
    useIpAliasesChange: Ember.observer('config.useIpAliases', function () {
      if (this.saving) {
        return;
      }

      if (Ember.get(this, 'config.useIpAliases')) {
        if (!Ember.isEmpty(this.config.subNetwork)) {
          Ember.set(this, 'config.ipPolicyCreateSubnetwork', false);
        }
      } else {
        Ember.setProperties(this, {
          'config.enablePrivateNodes': false,
          'config.ipPolicyCreateSubnetwork': false
        });
      }
    }),
    enablePrivateNodesChange: Ember.observer('config.enablePrivateNodes', function () {
      if (this.saving) {
        return;
      }

      const config = Ember.get(this, 'config');

      if (!Ember.get(config, 'enablePrivateNodes')) {
        Ember.setProperties(config, {
          enablePrivateEndpoint: false,
          masterIpv4CidrBlock: ''
        });
      }
    }),
    zoneChoices: Ember.computed('zones.[]', function () {
      let out = (Ember.get(this, 'zones') || []).slice();
      out.forEach(obj => {
        Ember.set(obj, 'sortName', (0, _util.sortableNumericSuffix)(obj.name));
        Ember.set(obj, 'displayName', `${obj.name} (${obj.description})`);
        Ember.set(obj, 'disabled', obj.status.toLowerCase() !== 'up');
      });
      return out.sortBy('sortName');
    }),
    machineChoices: Ember.computed('machineTypes.[]', function () {
      let out = (Ember.get(this, 'machineTypes') || []).slice();
      out.forEach(obj => {
        Ember.set(obj, 'sortName', (0, _util.sortableNumericSuffix)(obj.name));
        Ember.set(obj, 'displayName', `${obj.name} (${obj.description})`);
      });
      return out.sortBy('sortName');
    }),
    editedMachineChoice: Ember.computed('config.machineType', 'machineChoices', function () {
      return Ember.get(this, 'machineChoices').findBy('name', Ember.get(this, 'config.machineType'));
    }),
    versionChoices: Ember.computed('versions.validMasterVersions.[]', 'config.masterVersion', function () {
      const {
        versions: {
          validMasterVersions = []
        },
        config: {
          masterVersion
        },
        mode
      } = this;
      return this.versionChoiceService.parseCloudProviderVersionChoices(validMasterVersions, masterVersion, mode);
    }),
    locationContent: Ember.computed('config.zone', 'zoneChoices', function () {
      const zone = Ember.get(this, 'config.zone');

      if (!zone) {
        return [];
      }

      const arr = zone.split('-');
      const locationName = `${arr[0]}-${arr[1]}`;
      const zoneChoices = Ember.get(this, 'zoneChoices');
      return zoneChoices.filter(z => (z.name || '').startsWith(locationName) && z.name !== zone);
    }),
    networkContent: Ember.computed('networks', 'config.zone', function () {
      return Ember.get(this, 'networks');
    }),
    subNetworkContent: Ember.computed('subNetworks.[]', 'config.network', 'config.zone', function () {
      const {
        config: {
          network: networkName
        },
        networkContent,
        subNetworks = []
      } = this;
      const filteredSubnets = (subNetworks || []).filter(s => {
        const network = networkContent.findBy('selfLink', s.network);
        const networkDisplayName = network.name;

        if (networkDisplayName === networkName) {
          return true;
        }
      });
      const mappedSubnets = filteredSubnets.map(o => {
        const network = networkContent.findBy('selfLink', o.network);
        const networkDisplayName = network.name;
        return {
          label: `${o.name}(${o.ipCidrRange})`,
          value: o.name,
          secondaryIpRanges: o.secondaryIpRanges,
          networkDisplayName
        };
      });
      const defaultSubnetAry = [{
        label: this.intl.t('clusterNew.googlegke.ipPolicyCreateSubnetwork.autoLabel'),
        value: ''
      }];
      return [...defaultSubnetAry, ...mappedSubnets];
    }),
    secondaryIpRangeContent: Ember.computed('subNetworkContent.[]', 'config.{network,subNetwork}', function () {
      const {
        subNetworkContent = [],
        config: {
          subNetwork
        }
      } = this;
      const subNetworkMatch = subNetworkContent.findBy('value', subNetwork);

      if (subNetworkMatch) {
        const {
          secondaryIpRanges = []
        } = subNetworkMatch;
        return secondaryIpRanges.map(s => {
          return {
            label: `${s.rangeName}(${s.ipCidrRange})`,
            value: s.rangeName
          };
        });
      }

      return [];
    }),
    serviceAccountContent: Ember.computed('serviceAccounts', function () {
      const serviceAccounts = Ember.get(this, 'serviceAccounts');
      return serviceAccounts;
    }),
    maintenanceWindowChoice: Ember.computed('maintenanceWindowTimes.[]', 'config.maintenanceWindow', function () {
      return Ember.get(this, 'maintenanceWindowTimes').findBy('value', Ember.get(this, 'config.maintenanceWindow')) || {
        label: 'Any Time'
      };
    }),
    shouldAllowDisableCreateSubNetwork: Ember.computed('config.subNetwork', function () {
      const {
        config: {
          subNetwork
        },
        secondaryIpRangeContent,
        editing
      } = this;

      if (Ember.isEmpty(subNetwork)) {
        return true;
      }

      if (editing && Ember.isEmpty(secondaryIpRangeContent)) {
        return true;
      }

      return false;
    }),

    fetchZones() {
      if (this.saved) {
        return;
      }

      return Ember.get(this, 'globalStore').rawRequest({
        url: '/meta/gkeZones',
        method: 'POST',
        data: {
          credentials: Ember.get(this, 'config.credential'),
          projectId: Ember.get(this, 'config.projectId')
        }
      }).then(xhr => {
        const out = xhr.body.items;
        const locations = Ember.get(this, 'config.locations') || [];

        if (locations.length > 0) {
          out.map(o => {
            if (locations.includes(o.name)) {
              Ember.set(o, 'checked', true);
            }
          });
        }

        Ember.set(this, 'zones', out);
        return out;
      }).catch(xhr => {
        Ember.set(this, 'errors', [xhr.body.error]);
        return Ember.RSVP.reject();
      });
    },

    fetchVersions() {
      if (this.saved) {
        return;
      }

      return Ember.get(this, 'globalStore').rawRequest({
        url: '/meta/gkeVersions',
        method: 'POST',
        data: {
          credentials: Ember.get(this, 'config.credential'),
          projectId: Ember.get(this, 'config.projectId'),
          zone: Ember.get(this, 'config.zone') || `${Ember.get(this, 'config.region')}-b`
        }
      }).then(xhr => {
        const out = xhr.body;
        Ember.set(this, 'versions', out);
        this.versionChanged();
        return out;
      }).catch(xhr => {
        Ember.set(this, 'errors', [xhr.body.error]);
        return Ember.RSVP.reject();
      });
    },

    fetchMachineTypes() {
      if (this.saved) {
        return;
      }

      return Ember.get(this, 'globalStore').rawRequest({
        url: '/meta/gkeMachineTypes',
        method: 'POST',
        data: {
          credentials: Ember.get(this, 'config.credential'),
          projectId: Ember.get(this, 'config.projectId'),
          zone: Ember.get(this, 'config.zone') || `${Ember.get(this, 'config.region')}-b`
        }
      }).then(xhr => {
        const out = xhr.body.items;
        Ember.set(this, 'machineTypes', out);
        return out;
      }).catch(xhr => {
        Ember.set(this, 'errors', [xhr.body.error]);
        return Ember.RSVP.reject();
      });
    },

    fetchNetworks() {
      if (this.saved) {
        return;
      }

      return Ember.get(this, 'globalStore').rawRequest({
        url: '/meta/gkeNetworks',
        method: 'POST',
        data: {
          credentials: Ember.get(this, 'config.credential'),
          projectId: Ember.get(this, 'config.projectId'),
          zone: Ember.get(this, 'config.zone')
        }
      }).then(xhr => {
        const out = xhr.body.items || [];
        Ember.set(this, 'networks', out);

        if (Ember.get(this, 'mode') === 'new') {
          Ember.set(this, 'config.network', out[0] && out[0].name);
        }

        return out;
      }).catch(xhr => {
        Ember.set(this, 'errors', [xhr.body.error]);
        return Ember.RSVP.reject();
      });
    },

    fetchSubnetworks() {
      if (this.saved) {
        return;
      }

      const zone = Ember.get(this, 'config.zone');
      const locationType = Ember.get(this, 'locationType');
      return Ember.get(this, 'globalStore').rawRequest({
        url: '/meta/gkeSubnetworks',
        method: 'POST',
        data: {
          credentials: Ember.get(this, 'config.credential'),
          projectId: Ember.get(this, 'config.projectId'),
          region: locationType === this.google.defaultZoneType ? `${zone.split('-')[0]}-${zone.split('-')[1]}` : Ember.get(this, 'config.region')
        }
      }).then(xhr => {
        const out = xhr.body.items || [];
        Ember.set(this, 'subNetworks', out);
        return out;
      }).catch(xhr => {
        Ember.set(this, 'errors', [xhr.body.error]);
        return Ember.RSVP.reject();
      });
    },

    fetchServiceAccounts() {
      if (this.saved) {
        return;
      }

      return Ember.get(this, 'globalStore').rawRequest({
        url: '/meta/gkeServiceAccounts',
        method: 'POST',
        data: {
          credentials: Ember.get(this, 'config.credential'),
          projectId: Ember.get(this, 'config.projectId'),
          zone: Ember.get(this, 'config.zone')
        }
      }).then(xhr => {
        const out = xhr.body.accounts || [];
        Ember.set(this, 'serviceAccounts', out);
        const filter = out.filter(o => o.displayName === 'Compute Engine default service account');

        if (Ember.get(this, 'mode') === 'new') {
          Ember.set(this, 'config.serviceAccount', filter[0] && filter[0].uniqueId);
        }

        return out;
      }).catch(xhr => {
        Ember.set(this, 'errors', [xhr.body.error]);
        return Ember.RSVP.reject();
      });
    },

    validate() {
      const model = Ember.get(this, 'cluster');
      const errors = model.validationErrors();
      const {
        intl,
        config = {}
      } = this;
      let {
        minNodeCount,
        maxNodeCount,
        enableNodepoolAutoscaling,
        nodeCount
      } = config;

      if (enableNodepoolAutoscaling) {
        if (nodeCount && maxNodeCount && minNodeCount) {
          nodeCount = parseInt(nodeCount, 10);
          maxNodeCount = parseInt(maxNodeCount, 10);
          minNodeCount = parseInt(minNodeCount, 10);

          if (maxNodeCount < minNodeCount) {
            errors.pushObject(intl.t('clusterNew.googlegke.maxNodeCount.minError'));
          }

          if (enableNodepoolAutoscaling && maxNodeCount < nodeCount) {
            errors.pushObject(intl.t('clusterNew.googlegke.nodeCount.outsideError'));
          }

          if (enableNodepoolAutoscaling && minNodeCount > nodeCount) {
            errors.pushObject(intl.t('clusterNew.googlegke.nodeCount.outsideError'));
          }
        } else {
          if (!nodeCount) {
            errors.pushObject(intl.t('clusterNew.googlegke.nodeCount.required'));
          }

          if (!maxNodeCount) {
            errors.pushObject(intl.t('clusterNew.googlegke.maxNodeCount.required'));
          }

          if (!minNodeCount) {
            errors.pushObject(intl.t('clusterNew.googlegke.minNodeCount.required'));
          }
        }
      }

      if (!Ember.get(this, 'cluster.name')) {
        errors.pushObject(intl.t('clusterNew.name.required'));
      }

      const taints = Ember.get(this, 'taints') || [];

      if (taints.length > 0) {
        const filter = taints.filter(t => !t.key || !t.value);

        if (filter.length > 0) {
          errors.pushObject(intl.t('clusterNew.googlegke.taints.required'));
        }
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },

    willSave() {
      const config = Ember.get(this, 'config') || {};
      const locationType = Ember.get(this, 'locationType');

      if (locationType === this.google.defaultZoneType) {
        Ember.set(config, 'region', null);
      } else {
        Ember.set(config, 'zone', null);
      }

      if (!Ember.get(config, 'enableNodepoolAutoscaling')) {
        Ember.setProperties(config, {
          minNodeCount: 0,
          maxNodeCount: 0
        });
      }

      if (Ember.get(this, 'config.useIpAliases') && Ember.get(config, 'ipPolicyCreateSubnetwork') && Ember.get(config, 'ipPolicyClusterIpv4CidrBlock')) {
        Ember.set(config, 'clusterIpv4Cidr', '');
      }

      if (!Ember.get(config, 'enableMasterAuthorizedNetwork')) {
        delete config.masterAuthorizedNetworkCidrBlocks;
      }

      if (!Ember.get(config, 'resourceLabels')) {
        delete config.resourceLabels;
      }

      const locationContent = Ember.get(this, 'locationContent');
      const locations = locationContent.filter(l => l.checked).map(l => l.name);

      if (locations.length > 0) {
        locations.push(Ember.get(config, 'zone'));
        Ember.set(config, 'locations', locations);
      } else {
        delete config.locations;
      }

      const oauthScopesSelection = Ember.get(this, 'oauthScopesSelection');
      const scopeConfig = Ember.get(this, 'scopeConfig');
      Ember.set(config, 'oauthScopes', this.google.mapOauthScopes(oauthScopesSelection, scopeConfig));
      const taints = Ember.get(this, 'taints') || [];

      if (taints.length > 0) {
        Ember.set(config, 'taints', taints.map(t => {
          return `${t.effect}:${t.key}=${t.value}`;
        }));
      } else {
        Ember.set(config, 'taints', []);
      }

      Ember.set(config, 'issueClientCertificate', true);
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});