define("global-admin/components/cru-cloud-credential/component", ["exports", "shared/mixins/view-new-edit", "global-admin/components/cru-cloud-credential/template", "shared/utils/amazon", "shared/utils/oci"], function (_exports, _viewNewEdit, _template, _amazon, _oci) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CRED_CONFIG_CHOICES = [{
    name: 'amazon',
    displayName: 'Amazon',
    driver: 'amazonec2',
    configField: 'amazonec2credentialConfig'
  }, {
    name: 'azure',
    displayName: 'Azure',
    driver: 'azure',
    configField: 'azurecredentialConfig'
  }, {
    name: 'digitalOcean',
    displayName: 'Digital Ocean',
    driver: 'digitalocean',
    configField: 'digitaloceancredentialConfig'
  }, {
    name: 'google',
    displayName: 'Google',
    driver: 'google',
    configField: 'googlecredentialConfig'
  }, {
    name: 'linode',
    displayName: 'Linode',
    driver: 'linode',
    configField: 'linodecredentialConfig'
  }, {
    name: 'oci',
    displayName: 'OCI',
    driver: 'oci',
    configField: 'ocicredentialConfig'
  }, {
    name: 'pnap',
    displayName: 'phoenixNAP',
    driver: 'pnap',
    configField: 'pnapcredentialConfig'
  }, {
    name: 'vmware',
    displayName: 'VMware vSphere',
    driver: 'vmwarevsphere',
    configField: 'vmwarevspherecredentialConfig'
  }];

  var _default = Ember.Component.extend(_viewNewEdit.default, {
    globalStore: Ember.inject.service(),
    digitalOcean: Ember.inject.service(),
    linode: Ember.inject.service(),
    oci: Ember.inject.service(),
    intl: Ember.inject.service(),
    google: Ember.inject.service(),
    layout: _template.default,
    nodeConfigTemplateType: null,
    cloudCredentialType: null,
    model: null,
    cancelAdd: null,
    doneSavingCloudCredential: null,
    disableHeader: false,
    validatingKeys: false,
    region: null,
    sinlgeCloudKeyChoice: null,
    regionChoices: _amazon.REGIONS,
    ociRegionChoices: _oci.OCI_REGIONS,
    mode: 'new',
    urlInvalid: false,
    urlWarning: null,
    urlError: null,
    gkeProjectId: null,

    init() {
      this._super(...arguments);

      let cloudCredentialType = 'amazon';
      let model = null;

      if (Ember.get(this, 'driverName')) {
        let match = CRED_CONFIG_CHOICES.findBy('driver', Ember.get(this, 'driverName'));
        cloudCredentialType = Ember.get(match, 'name');
        model = this.globalStore.createRecord({
          type: 'cloudCredential'
        });
      } else {
        if (Ember.get(this, 'originalModel')) {
          let configField = Object.keys(this.originalModel).find(key => key.toLowerCase().includes('config'));
          let configChoice = CRED_CONFIG_CHOICES.findBy('configField', configField);
          cloudCredentialType = Ember.get(configChoice, 'name');
          model = this.originalModel.clone();
        } else {
          model = this.globalStore.createRecord({
            type: 'cloudCredential'
          });
        }
      }

      Ember.setProperties(this, {
        cloudCredentialType,
        model
      });

      if (!Ember.get(this, 'originalModel')) {
        this.initCloudCredentialConfig();
      }
    },

    actions: {
      selectConfig(configType) {
        this.cleanupPreviousConfig();
        Ember.set(this, 'cloudCredentialType', configType);
        this.initCloudCredentialConfig();
      }

    },
    config: Ember.computed('cloudCredentialType', 'model.{amazonec2credentialConfig,azurecredentialConfig,digitaloceancredentialConfig,googlecredentialConfig,linodecredentialConfig,ocicredentialConfig,pnapcredentialConfig,vmwarevspherecredentialConfig}', function () {
      const {
        model
      } = this;
      const configField = this.getConfigField();
      return Ember.get(model, configField);
    }),
    configChoices: Ember.computed('driverName', function () {
      if (Ember.get(this, 'driverName')) {
        const {
          driverName
        } = this;
        let match = CRED_CONFIG_CHOICES.findBy('driver', driverName);
        Ember.run.next(() => {
          Ember.setProperties(this, {
            cloudCredentialType: Ember.get(match, 'name'),
            singleCloudKeyChoice: Ember.get(match, 'displayName')
          });
          this.initCloudCredentialConfig();
        });
        return [match];
      } else {
        return CRED_CONFIG_CHOICES.sortBy('displayName');
      }
    }),
    savingLabel: Ember.computed('validatingKeys', 'cloudCredentialType', function () {
      if (this.validatingKeys) {
        switch (this.cloudCredentialType) {
          case 'amazon':
          case 'digitalOcean':
          case 'linode':
          case 'google':
            return 'modalAddCloudKey.saving.validating';

          case 'oci':
          case 'pnap':
          case 'azure':
          case 'vmware':
          default:
            return 'saveCancel.saving';
        }
      }

      return 'saveCancel.saving';
    }),

    validate() {
      var ok = this._super(...arguments);

      let errors = [];
      const {
        cloudCredentialType
      } = this;

      if (cloudCredentialType === 'amazon') {
        if (!Ember.get(this, 'region')) {
          ok = false;
          errors.pushObject(this.intl.t('modalAddCloudKey.errors.region'));
        }
      }

      this.parseAndCollectErrors(errors, true);
      return ok;
    },

    willSave() {
      let ok = this._super(...arguments);

      if (!ok) {
        return ok;
      }

      const {
        cloudCredentialType
      } = this;
      const keysThatWeCanValidate = ['amazon', 'digitalOcean', 'linode', 'oci', 'google'];
      const auth = {
        type: 'validate',
        token: null
      };

      if (keysThatWeCanValidate.includes(cloudCredentialType)) {
        Ember.set(this, 'validatingKeys', true);

        if (cloudCredentialType === 'linode') {
          Ember.set(auth, 'token', Ember.get(this, 'config.token'));
          return this.linode.request(auth, 'profile').then(() => {
            Ember.set(this, 'validatingKeys', false);
            return true;
          }).catch(err => {
            return this.setError(`${err.status} ${err.statusText}`);
          });
        }

        if (cloudCredentialType === 'digitalOcean') {
          Ember.set(auth, 'token', Ember.get(this, 'config.accessToken'));
          return this.digitalOcean.request(auth, 'regions').then(() => {
            Ember.set(this, 'validatingKeys', false);
            return true;
          }).catch(err => {
            return this.setError(`${err.status} ${err.statusText}`);
          });
        }

        if (cloudCredentialType === 'amazon') {
          let authConfig = {
            accessKeyId: this.config.accessKey,
            secretAccessKey: this.config.secretKey,
            region: this.region
          };
          let ec2 = new AWS.EC2(authConfig);
          return new Ember.RSVP.Promise((resolve, reject) => {
            ec2.describeAccountAttributes({}, err => {
              if (err) {
                reject(err);
              }

              return resolve();
            });
          }).then(() => {
            Ember.set(this, 'validatingKeys', false);
            return true;
          }).catch(err => {
            return this.setError(`${err.statusCode} ${err.code}`);
          });
        }

        if (cloudCredentialType === 'oci') {
          let authConfig = {
            region: this.config.region,
            tenancyOCID: this.config.tenancyId,
            userOCID: this.config.userId,
            fingerprint: this.config.fingerprint,
            privateKey: this.config.privateKeyContents,
            privateKeyPassphrase: this.config.privateKeyPassphrase,
            token: Ember.get(this, 'config.token')
          };
          return this.oci.request(authConfig, 'availabilityDomains').then(() => {
            Ember.set(this, 'validatingKeys', false);
            return true;
          }).catch(err => {
            return this.setError(`${err.message}`);
          });
        }

        if (cloudCredentialType === 'google') {
          return this.fetchZones().then(() => {
            const auth = JSON.parse(Ember.get(this, 'config.authEncodedJson'));
            const projectId = auth === null || auth === void 0 ? void 0 : auth.project_id;
            Ember.set(this, 'gkeProjectId', projectId);
            Ember.set(this, 'validatingKeys', false);
            return true;
          }).catch(err => {
            return this.setError(`${err.message}`);
          });
        }
      }

      Ember.set(this, 'validatingKeys', false);
      return ok;
    },

    setError(message = '') {
      const translation = this.intl.t('modalAddCloudKey.errors.validation', {
        status: message
      });
      Ember.set(this, 'validatingKeys', false);
      this.parseAndCollectErrors(translation, true);
      return false;
    },

    initCloudCredentialConfig() {
      const {
        model
      } = this;
      const configField = this.getConfigField();

      if (configField) {
        Ember.set(model, configField, this.globalStore.createRecord({
          type: configField.toLowerCase()
        }));
      }
    },

    doneSaving(neu) {
      const driverName = Ember.get(this, 'driverName');
      const projectId = Ember.get(this, 'gkeProjectId');

      if (driverName === 'google' && projectId) {
        Ember.set(neu, this.getConfigField(), {
          projectId
        });
        Ember.set(this, 'gkeProjectId', null);
      } else {
        // API sends back empty object which doesn't overrite the keys when the response is merged.
        // Just need to ensure that when the user loads this model again the acceess key/secret/pw is not present.
        Ember.set(neu, this.getConfigField(), {});
      }

      this.model.replaceWith(neu);
      this.doneSavingCloudCredential(neu);
    },

    cleanupPreviousConfig() {
      const {
        model
      } = this;
      const configField = this.getConfigField();

      if (configField) {
        delete model[configField];
      }
    },

    getConfigField() {
      const {
        cloudCredentialType,
        configChoices
      } = this;

      if (cloudCredentialType) {
        const matchType = configChoices.findBy('name', cloudCredentialType);
        return Ember.get(matchType, 'configField');
      }

      return;
    },

    parseNodeTemplateConfigType(nodeTemplate) {
      return Object.keys(nodeTemplate).find(f => f.toLowerCase().indexOf('config') > -1);
    },

    parseAndCollectErrors() {
      throw new Error('parseAndCollectErrors action is required!');
    },

    fetchZones() {
      let credentials = null;
      let config = null;
      let projectId = null;

      try {
        credentials = Ember.get(this, 'config.authEncodedJson');
        config = JSON.parse(credentials || '{}');
        projectId = Ember.get(config, 'project_id');
      } catch (error) {
        return Ember.RSVP.Promise.reject({
          message: 'Invalid JSON'
        });
      }

      return Ember.get(this, 'globalStore').rawRequest({
        url: '/meta/gkeZones',
        method: 'POST',
        data: {
          credentials,
          projectId
        }
      }).then(() => {
        return Ember.RSVP.Promise.resolve();
      }).catch(xhr => {
        var _xhr$body;

        return Ember.RSVP.Promise.reject({
          message: xhr === null || xhr === void 0 ? void 0 : (_xhr$body = xhr.body) === null || _xhr$body === void 0 ? void 0 : _xhr$body.error
        });
      });
    }

  });

  _exports.default = _default;
});