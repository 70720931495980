define("nodes/components/driver-pnap/component", ["exports", "shared/mixins/node-driver", "nodes/components/driver-pnap/template"], function (_exports, _nodeDriver, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DRIVER = 'pnap';
  const CONFIG = 'pnapConfig';
  const LOCATION_CHOICES = [{
    value: 'PHX'
  }, {
    value: 'ASH'
  }];
  const OS_CHOICES = [{
    value: 'ubuntu/bionic'
  }, {
    value: 'centos/centos7'
  }];
  const TYPE_CHOICES = [{
    value: 's1.c1.small'
  }, {
    value: 's1.c1.medium'
  }, {
    value: 's1.c2.medium'
  }, {
    value: 's1.c2.large'
  }, {
    value: 'd1.c1.small'
  }, {
    value: 'd1.c1.medium'
  }, {
    value: 'd1.c1.large'
  }, {
    value: 'd1.m1.medium'
  }];

  var _default = Ember.Component.extend(_nodeDriver.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    driverName: DRIVER,
    locationChoices: LOCATION_CHOICES,
    osChoices: OS_CHOICES,
    typeChoices: TYPE_CHOICES,
    model: null,
    config: Ember.computed.alias(`model.${CONFIG}`),
    actions: {
      finishAndSelectCloudCredential(credential) {
        Ember.set(this, 'model.cloudCredentialId', Ember.get(credential, 'id'));
      }

    },

    bootstrap() {
      let config = Ember.get(this, 'globalStore').createRecord({
        type: CONFIG,
        serverLocation: 'PHX',
        serverType: 's1.c1.medium',
        serverOs: 'ubuntu/bionic',
        serverHostname: 'host'
      });
      Ember.set(this, `model.${CONFIG}`, config);
    },

    validate() {
      this._super();

      let errors = Ember.get(this, 'errors') || [];

      if (!Ember.get(this, 'model.name')) {
        errors.push(this.intl.t('nodeDriver.nameError'));
      }

      if (!this.validateCloudCredentials()) {
        errors.push(this.intl.t('nodeDriver.cloudCredentialError'));
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());
        return false;
      }

      return true;
    }

  });

  _exports.default = _default;
});