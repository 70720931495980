define("shared/components/form-auth-cloud-credential/component", ["exports", "shared/components/form-auth-cloud-credential/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    showAddCloudCredential: null,
    region: null,
    // only used a passthrough for the amazon region so the region selection can happen in cloud creds rather than have the markup on both pages
    hideSave: false,
    cloudCredentials: null,
    driverName: null,
    primaryResource: null,
    finishAndSelectCloudCredential: null,
    progressStep: null,
    cancel: null,
    changeCloudCredential: null,
    disableSave: false,
    createLabel: 'saveCancel.create',
    savingLabel: 'generic.loading',
    mode: 'new',
    cloudCredentialKey: 'cloudCredentialId',

    init() {
      this._super(...arguments);

      if (this.mode === 'new') {
        this.initSingleOrAddCredential();
      }
    },

    actions: {
      setCloudCredential(cred) {
        if (this.changeCloudCredential) {
          // send the entire credential to match the signature of doneSaving...
          this.changeCloudCredential((this.cloudCredentials || []).find(cc => cc.id === cred));
        } else {
          const {
            primaryResource,
            cloudCredentialKey
          } = this;
          Ember.set(primaryResource, cloudCredentialKey, cred);
        }
      },

      doneSavingCloudCredential(cred) {
        if (cred) {
          if (this.finishAndSelectCloudCredential) {
            this.finishAndSelectCloudCredential(cred);
          }

          Ember.set(this, 'showAddCloudCredential', false);
        }
      },

      addCloudCredential() {
        const {
          primaryResource = {},
          cloudCredentialKey
        } = this;

        if (Ember.get(primaryResource, cloudCredentialKey)) {
          this.send('setCloudCredential', null);
        }

        Ember.set(this, 'showAddCloudCredential', true);
      },

      cancleNewCloudCredential() {
        Ember.set(this, 'showAddCloudCredential', false);
      }

    },

    initSingleOrAddCredential() {
      let {
        cloudCredentials = [],
        primaryResource,
        cloudCredentialKey
      } = this;
      let singleCloudCredentialId = Ember.get(cloudCredentials || [], 'firstObject.id') || null;
      const cloudCredentialValue = Ember.get(primaryResource || {}, cloudCredentialKey);

      if (Ember.isEmpty(Ember.get(this, 'cloudCredentials'))) {
        Ember.run.next(() => {
          Ember.set(this, 'showAddCloudCredential', true);
        });
      }

      Ember.run.next(() => {
        if (primaryResource && (!cloudCredentialValue || !cloudCredentials.find(c => c.id === cloudCredentialValue))) {
          this.send('setCloudCredential', singleCloudCredentialId);
        }
      });
    }

  });

  _exports.default = _default;
});